.documentContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.documentContainer :global(.react-pdf__message--loading) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.react-pdf__Page {
  width: min-content;
  margin-left: auto;
  margin-right: auto;
}

.documentLoaderContainer {
  position: fixed;
  top: 50%;
  left: 50%;
}
