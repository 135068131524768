
#outerContainer {
  --dir-factor: 1;
  /*#if !MOZCENTRAL*/
  --inline-start: left;
  --inline-end: right;
  /*#endif*/

  --sidebar-width: 300px;
  --sidebar-transition-duration: 200ms;
  --sidebar-transition-timing-function: ease;

  --toolbar-icon-opacity: 0.7;
  --editor-toolbar-base-offset: 105px;

  --main-color: rgb(12 12 13);
  --body-bg-color: rgb(212 212 215);
  --scrollbar-color: auto;
  --scrollbar-bg-color: auto;
  --toolbar-icon-bg-color: rgb(0 0 0);
  --toolbar-icon-hover-bg-color: rgb(0 0 0);

  --sidebar-narrow-bg-color: rgb(212 212 215 / 0.9);
  --sidebar-toolbar-bg-color: rgb(245 246 247);
  --toolbar-bg-color: rgb(249 249 250);
  --toolbar-border-color: rgb(184 184 184);
  --toolbarSidebar-box-shadow: inset calc(-1px * var(--dir-factor)) 0 0
      rgb(0 0 0 / 0.25),
    0 1px 0 rgb(0 0 0 / 0.15), 0 0 1px rgb(0 0 0 / 0.1);
  --toolbarSidebar-border-bottom: none;
  --button-hover-color: rgb(221 222 223);
  --toggled-btn-color: rgb(0 0 0);
  --toggled-btn-bg-color: rgb(0 0 0 / 0.3);
  --toggled-hover-active-btn-color: rgb(0 0 0 / 0.4);
  --toggled-hover-btn-outline: none;
  --treeitem-color: rgb(0 0 0 / 0.8);
  --treeitem-bg-color: rgb(0 0 0 / 0.15);
  --treeitem-hover-color: rgb(0 0 0 / 0.9);
  --treeitem-selected-color: rgb(0 0 0 / 0.9);
  --treeitem-selected-bg-color: rgb(0 0 0 / 0.25);
  --thumbnail-hover-color: rgb(0 0 0 / 0.1);
  --thumbnail-selected-color: rgb(0 0 0 / 0.2);
  --dialog-button-bg-color: rgb(12 12 13 / 0.1);

  --treeitem-expanded-icon: url(../../../assets/images/treeitem-expanded.svg);
  --treeitem-collapsed-icon: url(../../../assets/images/treeitem-collapsed.svg);
  --toolbarButton-viewThumbnail-icon: url(../../../assets/images/toolbarButton-viewThumbnail.svg);
  --toolbarButton-viewOutline-icon: url(../../../assets/images/toolbarButton-viewOutline.svg);
}

#outerContainer:dir(rtl) {
  --dir-factor: -1;
  /*#if !MOZCENTRAL*/
  --inline-start: right;
  --inline-end: left;
  /*#endif*/
}

@media (prefers-color-scheme: dark) {
  #outerContainer {
    --main-color: rgb(249 249 250);
    --body-bg-color: rgb(42 42 46);
    --scrollbar-color: rgb(121 121 123);
    --scrollbar-bg-color: rgb(35 35 39);
    --toolbar-icon-bg-color: rgb(255 255 255);
    --toolbar-icon-hover-bg-color: rgb(255 255 255);

    --sidebar-narrow-bg-color: rgb(42 42 46 / 0.9);
    --sidebar-toolbar-bg-color: rgb(50 50 52);
    --toolbar-border-color: rgb(12 12 13);
    --button-hover-color: rgb(102 102 103);
    --toggled-btn-color: rgb(255 255 255);
    --toggled-btn-bg-color: rgb(0 0 0 / 0.3);
    --toggled-hover-active-btn-color: rgb(0 0 0 / 0.4);
    --treeitem-color: rgb(255 255 255 / 0.8);
    --treeitem-bg-color: rgb(255 255 255 / 0.15);
    --treeitem-hover-color: rgb(255 255 255 / 0.9);
    --treeitem-selected-color: rgb(255 255 255 / 0.9);
    --treeitem-selected-bg-color: rgb(255 255 255 / 0.25);
    --thumbnail-hover-color: rgb(255 255 255 / 0.1);
    --doorhanger-border-color: rgb(39 39 43);
  }
}

@media screen and (forced-colors: active) {
  :root {
    --button-hover-color: Highlight;
    --toolbar-icon-opacity: 1;
    --toolbar-icon-bg-color: ButtonText;
    --toolbar-icon-hover-bg-color: ButtonFace;
    --toggled-hover-active-btn-color: ButtonText;
    --toggled-hover-btn-outline: 2px solid ButtonBorder;
    --toolbar-border-color: CanvasText;
    --toggled-btn-color: HighlightText;
    --toggled-btn-bg-color: LinkText;
    --doorhanger-border-color-whcm: 1px solid ButtonText;
    --main-color: CanvasText;
    --toolbarSidebar-box-shadow: none;
    --toolbarSidebar-border-bottom: 1px solid var(--toolbar-border-color);
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  #outerContainer {
    --sidebar-transition-duration: 0;
  }
}

#outerContainer {
  background-color: var(--body-bg-color);
  scrollbar-color: var(--scrollbar-color) var(--scrollbar-bg-color);
  width: 100%;
  height: 100%;

  &.wait::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100000;
    cursor: wait;
  }
}

.hidden,
[hidden] {
  display: none !important;
}

#outerContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

#sidebarContainer {
  position: absolute;
  inset-block: 0 0;
  inset-inline-start: calc(-1 * var(--sidebar-width));
  width: var(--sidebar-width);
  visibility: hidden;
  z-index: 100;
  font: message-box;
  border-top: 1px solid rgb(51 51 51);
  border-inline-end: var(--doorhanger-border-color-whcm);
  transition-property: inset-inline-start;
  transition-duration: var(--sidebar-transition-duration);
  transition-timing-function: var(--sidebar-transition-timing-function);
}

#outerContainer:is(.sidebarMoving, .sidebarOpen) #sidebarContainer {
  visibility: visible;
}
#outerContainer.sidebarOpen #sidebarContainer {
  inset-inline-start: 0;
}

#mainContainer {
  position: absolute;
  inset: 0;
  min-width: 350px;
}

#sidebarContent {
  inset-block: 32px 0;
  inset-inline-start: 0;
  overflow: auto;
  position: absolute;
  width: 100%;
  box-shadow: inset calc(-1px * var(--dir-factor)) 0 0 rgb(0 0 0 / 0.25);
}

#viewerContainer {
  overflow: auto;
  position: absolute;
  inset: 0 0 0;
  outline: none;
}
#viewerContainer:not(.pdfPresentationMode) {
  transition-duration: var(--sidebar-transition-duration);
  transition-timing-function: var(--sidebar-transition-timing-function);
}

#outerContainer.sidebarOpen #viewerContainer:not(.pdfPresentationMode) {
  inset-inline-start: var(--sidebar-width);
  transition-property: inset-inline-start;
}

#toolbarSidebar {
  width: 100%;
  height: 32px;
  background-color: var(--sidebar-toolbar-bg-color);
  box-shadow: var(--toolbarSidebar-box-shadow);
  border-bottom: var(--toolbarSidebar-border-bottom);
}

#toolbarSidebarLeft {
  float: var(--inline-start);
}

#toolbarSidebarLeft * {
  position: relative;
  float: var(--inline-start);
}

.splitToolbarButton {
  margin: 2px;
  display: inline-block;
}
.splitToolbarButton > .toolbarButton {
  float: var(--inline-start);
}

.toolbarButton {
  border: none;
  background: none;
  width: 28px;
  height: 28px;
  outline: none;
}

.toolbarButton > span {
  display: inline-block;
  width: 0;
  height: 0;
  overflow: hidden;
}

:is(.toolbarButton)[disabled] {
  opacity: 0.5;
}

.splitToolbarButton > .toolbarButton:is(:hover, :focus-visible),
.dropdownToolbarButton:hover {
  background-color: var(--button-hover-color);
}
.splitToolbarButton > .toolbarButton {
  position: relative;
  margin: 0;
}
#toolbarSidebar .splitToolbarButton > .toolbarButton {
  margin-inline-end: 2px;
}

.toolbarButton {
  min-width: 16px;
  margin: 2px 1px;
  padding: 2px 6px 0;
  border: none;
  border-radius: 2px;
  color: var(--main-color);
  font-size: 12px;
  line-height: 14px;
  user-select: none;
  cursor: default;
  box-sizing: border-box;
}

.toolbarButton:is(:hover, :focus-visible) {
  background-color: var(--button-hover-color);
}

:is(.toolbarButton).toggled,
.splitToolbarButton.toggled > .toolbarButton.toggled {
  background-color: var(--toggled-btn-bg-color);
  color: var(--toggled-btn-color);
}

:is(.toolbarButton).toggled:hover,
.splitToolbarButton.toggled > .toolbarButton.toggled:hover {
  outline: var(--toggled-hover-btn-outline) !important;
}

:is(.toolbarButton).toggled::before {
  background-color: var(--toggled-btn-color);
}

:is(.toolbarButton).toggled:hover:active,
.splitToolbarButton.toggled > .toolbarButton.toggled:hover:active {
  background-color: var(--toggled-hover-active-btn-color);
}

:is(.toolbarButton, .treeItemToggler)::before {
  /* All matching images have a size of 16x16
   * All relevant containers have a size of 28x28 */
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;

  content: "";
  background-color: var(--toolbar-icon-bg-color);
  mask-size: cover;
}

.toolbarButton::before {
  opacity: var(--toolbar-icon-opacity);
  top: 6px;
  left: 6px;
}

.toolbarButton:is(:hover, :focus-visible)::before {
  background-color: var(--toolbar-icon-hover-bg-color);
}

a:is(.toolbarButton)[href="#"] {
  opacity: 0.5;
  pointer-events: none;
}

#viewThumbnail::before {
  mask-image: var(--toolbarButton-viewThumbnail-icon);
}

#viewOutline::before {
  mask-image: var(--toolbarButton-viewOutline-icon);
  transform: scaleX(var(--dir-factor));
}

#thumbnailView,
#outlineView {
  position: absolute;
  width: 100%;
  inset-block: 0;
  padding: 4px 4px 0;
  overflow: auto;
  user-select: none;
}

#thumbnailView {
  /* width: calc(100% - 60px); */
  width: 100%;
  padding: 10px 30px 0;
}

#thumbnailView > a:is(:active, :focus) {
  outline: 0;
}

:global(.react-pdf__Thumbnail) {
  width: min-content;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

:global(.react-pdf__Thumbnail__page) {
  float: var(--inline-start);
  margin: 0 10px 5px;
  padding: 1px;
  border: 7px solid transparent;
  border-radius: 2px;
  width: min-content;
  background-color: transparent !important;
}

a:focus > :global(.react-pdf__Thumbnail__page),
:global(.react-pdf__Thumbnail__page):hover {
  border-color: var(--thumbnail-hover-color);
}

.treeWithDeepNesting > .treeItem,
.treeItem > .treeItems {
  margin-inline-start: 20px;
}

.treeItem > a {
  text-decoration: none;
  display: inline-block;
  /* Subtract the right padding (left, in RTL mode) of the container: */
  min-width: calc(100% - 4px);
  height: auto;
  margin-bottom: 1px;
  padding: 2px 0 5px;
  padding-inline-start: 4px;
  border-radius: 2px;
  color: var(--treeitem-color);
  font-size: 13px;
  line-height: 15px;
  user-select: none;
  white-space: normal;
  cursor: pointer;
}

.treeItemToggler {
  position: relative;
  float: var(--inline-start);
  height: 0;
  width: 0;
  color: rgb(255 255 255 / 0.5);
}
.treeItemToggler::before {
  inset-inline-end: 4px;
  mask-image: var(--treeitem-expanded-icon);
}
.treeItemToggler.treeItemsHidden::before {
  mask-image: var(--treeitem-collapsed-icon);
  transform: scaleX(var(--dir-factor));
}
.treeItemToggler.treeItemsHidden ~ .treeItems {
  display: none;
}

.treeItem.selected > a {
  background-color: var(--treeitem-selected-bg-color);
  color: var(--treeitem-selected-color);
}

.treeItemToggler:hover,
.treeItemToggler:hover + a,
.treeItemToggler:hover ~ .treeItems,
.treeItem > a:hover {
  background-color: var(--treeitem-bg-color);
  background-clip: padding-box;
  border-radius: 2px;
  color: var(--treeitem-hover-color);
}

@media all and (max-width: 840px) {
  #sidebarContainer {
    background-color: var(--sidebar-narrow-bg-color);
  }
  #outerContainer.sidebarOpen #viewerContainer {
    inset-inline-start: 0 !important;
  }
}

@media all and (max-width: 750px) {
  :root {
    --editor-toolbar-base-offset: 40px;
  }
}
